import React, { Component } from 'react'
import { navigateTo } from 'gatsby-link'

import { FlexContainer, Form, Label, Input, TextArea, Submit } from '../Scaffold.js'
import FormErrors from './FormErrors'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      formErrors: {name: '', email: '', message: ''},
      nameValid: false,
      emailValid: false,
      messageValid: false,
      formValid: false
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({
      [name]: value },
      () => { this.validateField(name, value)
    })
  }

  validateField(fieldName, value) {
  let fieldValidationErrors = this.state.formErrors
  let nameValid = this.state.nameValid
  let emailValid = this.state.emailValid
  let messageValid = this.state.messageValid

  switch(fieldName) {
    case 'name':
      nameValid = value.length > 0
      fieldValidationErrors.name = nameValid ? '': ' is too short'
      break
    case 'email':
      emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      fieldValidationErrors.email = emailValid ? '' : ' is invalid'
      break
    case 'message':
      messageValid = value.length >= 20
      fieldValidationErrors.message = messageValid ? '': ' is too short'
      break
    default:
      break
    }
    this.setState({
      formErrors: fieldValidationErrors,
      nameValid,
      emailValid,
      messageValid,
    }, this.validateForm)
  }

  validateForm() {
    this.setState({
      formValid: this.state.nameValid && this.state.emailValid && this.state.messageValid
    })
  }

  hasError(error) {
    return(error.length === 0 ? '' : 'has-error')
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => navigateTo(form.getAttribute("action")))
    .catch(error => alert(error))
  }

  render() {
    return (
      <Form
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* Honeypot field for bots. If this gets filled out the form won't be sent */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <Label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </Label>
        </p>
        <FlexContainer>
          <Label>
            <p>Your name:</p>
            <Input
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              className={this.hasError(this.state.formErrors.name)}
            />
          </Label>
          <Label>
            <p>Your email:</p>
            <Input
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              className={this.hasError(this.state.formErrors.email)}
            />
          </Label>
        </FlexContainer>
        <FlexContainer>
          <Label>
            <p>Message:</p>
            <TextArea
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
              className={this.hasError(this.state.formErrors.message)}
            />
          </Label>
        </FlexContainer>
        <FlexContainer style={{textAlign: 'center'}}>
          <Submit type="submit" disabled={!this.state.formValid}>Submit</Submit>
        </FlexContainer>
        <FlexContainer>
          <FormErrors formErrors={this.state.formErrors}/>
        </FlexContainer>
      </Form>
    )
  }
}

export default ContactForm
