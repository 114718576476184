import React, {Component} from 'react'

import Layout from '../components/Layout'
import { Content, Quiet, Hr, Button } from '../components/Scaffold'
import ContactForm from '../components/Forms/ContactForm'

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <Content>
          <div style={{textAlign: 'center', padding: '28px 0', maxWidth: '600px', margin: '0 auto'}}>
            <h1>Contact Us</h1>
            <p style={{fontSize: 22, fontWeight: 500, marginBottom: '0.5rem'}}>01706 350 716</p>
            <Quiet>Mon - Fri 8:00 AM - 5:00 PM</Quiet>
            <Button href="tel:01706350716">Call Us</Button>
          </div>
          <Hr />
          <div style={{padding: '28px 0', maxWidth: '600px', margin: '0 auto'}}>
            <h3 style={{textAlign: 'center', maxWidth: 400, margin: '0 auto 1.45rem'}}>Or fill out this form and we'll get back to you as soon as we can.</h3>
            <ContactForm />
          </div>
        </Content>
      </Layout>
    )
  }
}

export default ContactPage
